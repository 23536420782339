@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  font-family: 'Poppins', 'sans-serif';
  font-size: 16px;
  font-weight: 400;
}


.module-bg{
  background-image: url('../src/assets/images/circle-scatter-haikei-1.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}


.contact-form-bg{
  background-image: url('../src/assets/images/contact-info-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.about-bg{
  background-image: url('../src/assets/images/about-us-bg.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.product-bg{
  background-image: url('../src/assets/images/product-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.form-control{
  margin-bottom: 10px;
}

.form-control input, select, textarea {
  display: block;
  width: 100%;
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #dbdfe9;
  box-shadow: false;
  background-color: white;
  outline: none;
  margin: 3px 0;
}

.form-control input:disabled, select:disabled, textarea:disabled {
  @apply bg-slate-100
}

.form-control label{
  font-weight: 500;
  font-size: 14px;
  padding: 5px;
  margin-bottom: 10px;
}

.form-control input::placeholder select::placeholder, textarea::placeholder{
    color: #9c9c9c;
}

.input-group {
  @apply relative
}

.input-group input{
  @apply pe-[40px]
}

.input-group .icon{
  @apply absolute top-[10px] right-[10px] cursor-pointer
}

h1{
  font-size: 22px;
}

h2{
  font-size: 20px;
}

h3, h4{
  font-size: 18px;
}

/* p{
  margin-bottom: 4px;
} */

strong{
  @apply font-semibold
}


.product-details ul {
  list-style-type: disc;
  margin-left: 40px;
}

.product-details ol {
  list-style-type: decimal;
  margin-left: 40px;
}

.product-details ul li, ol li {
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 1.4;
}
